import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import Hero from './sections/hero/_index'
import Principios from './sections/principios/_index'
import Abrangencias from './sections/abrangencias/_index'
import Diretrizes from './sections/diretrizes/_index'
import Conformidade from './sections/conformidade/_index'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

const PoliticaFornecedores = () => {
  const lang = 'pt'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero lang={lang} />
        <Principios lang={lang} />
        <Abrangencias lang={lang} />
        <Diretrizes lang={lang} />
        <Conformidade lang={lang} />
      </Layout>
    </Wrapper>
  )
}

export default PoliticaFornecedores
